import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faExpand } from '@fortawesome/free-solid-svg-icons';
import './video.css';
//import vdo from './hola.mkv';

import { getVideoData } from '../../../../api/sentinelAPI';

const VideoComponent = ({ vdoSource: vdosrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [source, setSource] = useState(null);
  const videoRef = useRef();
  const videoContainerRef = useRef();

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleProgress = () => {
    const video = videoRef.current;

    // Check if duration is available before calculating progress
    if (video.duration) {
      const progressValue = (video.currentTime / video.duration) * 100;
      setProgress(progressValue);
    }
  };

  const toggleFullScreen = () => {
    if (videoContainerRef.current.requestFullscreen) {
      videoContainerRef.current.requestFullscreen();
    } else if (videoContainerRef.current.mozRequestFullScreen) {
      videoContainerRef.current.mozRequestFullScreen();
    } else if (videoContainerRef.current.webkitRequestFullscreen) {
      videoContainerRef.current.webkitRequestFullscreen();
    } else if (videoContainerRef.current.msRequestFullscreen) {
      videoContainerRef.current.msRequestFullscreen();
    }
  };

  useEffect(() => {
    const video = videoRef.current;

    // Check if video is defined before adding event listener
    if (video) {
      video.addEventListener('timeupdate', handleProgress);
    }

    return () => {
      // Check if video is defined before removing event listener
      if (video) {
        video.removeEventListener('timeupdate', handleProgress);
      }
    };
  }, []);

  useEffect(() => {
    async function getdata() {
      try {
        const data = await getVideoData();

        // console.log(data.data);
        setSource(data);
      } catch (error) {
        console.log(error);
      }
    }
    getdata();
    // eslint-disable-next-line
  }, []);
  // console.log(source);
  console.log(vdosrc);

  return (
    <div ref={videoContainerRef} className="video-component">
      {source ? (
        <video ref={videoRef} onClick={togglePlayPause}>
          <source src={source} type="video/mp4" />
        </video>
      ) : (
        <p>no video</p>
      )}
      <div className="controls">
        <div className="top-controls">
          <button onClick={togglePlayPause} className="play-pause-btn">
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
          </button>
          <input
            type="range"
            min="0"
            max="100"
            value={progress}
            onChange={(e) => {
              const newTime = (videoRef.current.duration / 100) * e.target.value;
              videoRef.current.currentTime = newTime;
              setProgress(e.target.value);
            }}
            className="progress-bar"
            step={0.1}
          />
        </div>
        <div className="bottom-controls">
          <input type="text" placeholder="Search" className="search-bar" />
          <button onClick={toggleFullScreen} className="fullscreen-btn">
            <FontAwesomeIcon icon={faExpand} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoComponent;
