import SideBar from '../sideBar';
import RightBar from './RightBar';
import VideoComponent from './videocomponent';
import MiddleController from './cardsControllers/middleController';
import FirstCard from './cardsControllers/firstCard';
import ThirdCard from './cardsControllers/thirdCard';
import Grid from '@mui/material/Grid';
import { useState } from 'react';

const VideoPage = () => {
  const [source, setSource] = useState('');
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={1.7}></Grid>
        <SideBar selField="videos" />
        <Grid item md={10.3}>
          <div className=" flex gap-2 w-full mb-3">
            <div className="  w-4/5 ">
              <div className="  w-full m-0 p-0">
                <VideoComponent vdoSource={source} />
              </div>
              <div className="flex mt-2 gap-1 mb-1 text-white">
                <div className="w-1/3 h-[300px] bg-[#454545] rounded-lg ">
                  <FirstCard vdoSource={source} />
                </div>
                <div className="w-1/3 h-[300px] bg-[#454545] rounded-lg">
                  <MiddleController />
                </div>
                <div className="w-1/3 h-[300px] bg-[#454545] rounded-lg">
                  <ThirdCard />
                </div>
              </div>
            </div>
            <div className="w-1/4">
              <RightBar videoSource={setSource} />
            </div>
          </div>
        </Grid>
      </Grid>
      {/* </section> */}
    </>
  );
};

export default VideoPage;
