import React, { useState } from 'react';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

const Card = () => {
  const [inputZone, setZoneValue] = useState('');
  const [inputGate, setGateValue] = useState('');

  const handleChange = (e) => {
    setZoneValue(e.target.value);
  };

  const handleAddZone = (e) => {
    e.preventDefault();
    if (inputZone.trim() !== '') {
      // onSubmit(inputZone);
      const lastIcon = zones[zones.length - 1].icon; // Get the icon of the new last element
      zones.pop();
      zones.push({ icon: lastIcon, text: inputZone });
      setZoneValue('');
    }
  };

  const handleGates = (e) => {
    setGateValue(e.target.value);
  };

  const handleAddGates = (e) => {
    e.preventDefault();
    if (inputGate.trim() !== '') {
      const lastIcon = gates[gates.length - 1].icon; // Get the icon of the new last element
      gates.pop();
      gates.push({ icon: lastIcon, text: inputGate });
      setGateValue('');
    }
  };

  const [zones, setZones] = useState([
    { icon: <div className="h-full w-full rounded-full bg-blue-300"></div>, text: 'Phones' },
    { icon: <div className="h-full w-full rounded-full bg-blue-500"></div>, text: 'Sales' },
    { icon: <div className="h-full w-full rounded-full bg-blue-700"></div>, text: 'Accessories' }
  ]);

  const addEmptyZone = () => {
    setZones([...zones, { icon: <div className="h-full w-full rounded-full bg-green-300"></div>, text: '' }]);
  };

  const [gates, setGates] = useState([
    {
      icon: (
        <div style={{ transform: 'rotate(135deg)' }}>
          <HorizontalRuleIcon color="success" fontSize="large" />
        </div>
      ),
      text: 'Entry'
    },
    {
      icon: (
        <div
          style={{
            transform: 'rotate(135deg)'
          }}
        >
          <HorizontalRuleIcon color="error" fontSize="large" />
        </div>
      ),
      text: 'Exit'
    }
  ]);

  const addEmptyGate = () => {
    setGates([...gates, { icon: <div className="h-full w-full rounded-full bg-yellow-500"></div>, text: '' }]);
  };

  const groundPlanes = [{ icon: <CheckSharpIcon color="success" fontSize="large" />, text: 'Set' }];

  return (
    <div className=" rounded-lg p-2 text-white w-full h-full scrollbar overflow-y-scroll ">
      <div className="text-2xl font-semibold flex justify-between  mb-2">
        Environment Config
        <HighlightAltIcon fontSize="large" />
      </div>
      <div className="text-md flex justify-between">
        Zones
        <div onClick={addEmptyZone}>
          <AddCircleIcon />
        </div>
      </div>
      <div className="flex p-0 mb-4 flex-wrap">
        {zones.map((zone, index) => (
          <div key={index} className="text-sm flex items-center justify-left rounded-full bg-neutral-500 text-white p-[0.5rem] pr-2 m-1">
            <div className="flex items-center justify-center h-5 w-5 mr-1">{zone.icon}</div>
            <span>
              {zone.text !== '' ? (
                zone.text
              ) : (
                <form onSubmit={handleAddZone}>
                  <input
                    type="text"
                    value={inputZone}
                    onChange={handleChange}
                    className="bg-transparent outline-none text-white border-b w-min-10"
                    placeholder=""
                  />
                </form>
              )}
            </span>
          </div>
        ))}
      </div>
      <div className=" flex justify-between">
        Gates
        <div onClick={addEmptyGate}>
          <AddCircleIcon />
        </div>
      </div>
      <div className="flex p-0 mb-4 flex-wrap">
        {gates.map((zone, index) => (
          <div key={index} className="text-sm flex items-center justify-left rounded-full bg-neutral-500 text-white p-[0.5rem] pr-2 m-2">
            <div className="flex items-center justify-center h-5 w-5 mr-1">{zone.icon}</div>
            <span>
              {zone.text !== '' ? (
                zone.text
              ) : (
                <form onSubmit={handleAddGates}>
                  <input
                    type="text"
                    value={inputGate}
                    onChange={handleGates}
                    className="bg-transparent outline-none text-white border-b w-10"
                    placeholder=""
                  />
                </form>
              )}
            </span>
          </div>
        ))}
      </div>
      <div className=" flex justify-between">Ground Plane</div>
      <div className="flex p-0">
        {groundPlanes.map((zone, index) => (
          <div key={index} className="text-sm flex items-center justify-left rounded-full bg-neutral-500 text-white p-1 pr-2 m-2">
            <div className="flex items-center justify-center h-6 w-6 mr-1">{zone.icon}</div>
            <span>{zone.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
